import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16eb1d32"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "confirm-section"
}
const _hoisted_2 = { class: "confirm-section__top-container" }
const _hoisted_3 = { class: "confirm-section__content-container" }
const _hoisted_4 = { class: "confirm-section__title" }
const _hoisted_5 = { class: "confirm-section__content" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "confirm-section__button-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserHeader = _resolveComponent("UserHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FooterUT = _resolveComponent("FooterUT")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UserHeader),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(this.translateData.policy_title), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", {
                innerHTML: this.translateData.policy_text
              }, null, 8, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: '/registration?accept=true',
                class: "confirm-section__button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.translateData.i_agree_button), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createVNode(_component_FooterUT)
      ]))
    : _createCommentVNode("", true)
}